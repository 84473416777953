import React from "react";
import DropDownLink from "./dropdown/DropDownLink";
import {
  BoomBox,
  MicVocal,
  Settings,
  Store,
  CircleDollarSign,
  Heart,
} from "lucide-react";
import Image from "next/image";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { useAnalytics } from "../../context/AnalyticsContext";
import { getAuth } from "firebase/auth";
import firebase_app from "../../utils/firebase";
import { usePathname } from "next/navigation";
import useSubscription from "../../hooks/useSubscription";
import Link from "next/link";
import Dropdown from "../dropdown/Dropdown";
import { DropdownMenuItem } from "@jammable/ui-components/DropdownMenu";
const getGreeting = () => {
  const currentHour = new Date().getHours();
  const greetings = [
    ["Hey, early riser", "Morning", "Sup", "Hey there", "What's up"],
    ["Yo", "Hey", "Hi there", "What's good", "Howdy"],
    ["Evening", "Hey you", "What's happening", "Hi", "Hiya"],
    [
      "Still up?",
      "Night owl, huh?",
      "Yo, night person",
      "Can't sleep?",
      "Late night vibes",
    ],
  ];

  let timeIndex;
  if (currentHour >= 5 && currentHour < 12) timeIndex = 0;
  else if (currentHour >= 12 && currentHour < 18) timeIndex = 1;
  else if (currentHour >= 18 && currentHour < 21) timeIndex = 2;
  else timeIndex = 3;

  const randomIndex = Math.floor(Math.random() * greetings[timeIndex].length);
  return greetings[timeIndex][randomIndex];
};

const ProfileMenu = ({ user }) => {
  const greeting = getGreeting();
  const auth = getAuth(firebase_app);

  const { hasCreatorPlan, hasPowerPlan } = useSubscription();
  const pathname = usePathname();
  const mixpanel = useAnalytics();
  const router = useRouter();

  console.log(encodeURIComponent(user?.username));
  return (
    <div className="tw-ml-auto  tw-h-auto tw-cursor-pointer tw-border-2 tw-border-solid tw-border-black tw-flex tw-rounded-full">
      <Dropdown
        dropdownItems={
          <>
            <DropdownMenuItem
              asChild
              className="tw-p-0 tw-cursor-pointer tw-fflex"
            >
              <Link
                href={`/@/${encodeURIComponent(user?.username)}`}
                className="tw-h-26 tw-p-5 tw-pt-3 hover:tw-bg-purple-300 tw-transition-all  tw-font-black tw-flex tw-flex-col tw-w-full tw-bg-gradient-to-t tw-from-white tw-to-purple-400/40"
              >
                <span className="tw-text-black/60 tw-text-sm">
                  {" "}
                  {greeting},
                </span>
                <span className="tw-text-xl"> {user?.username}</span>

                <div className="tw-text-black/60 tw-mt-1 tw-text-xs tw-tracking-widest tw-font-black ">
                  VIEW PROFILE
                </div>

                <div className="tw-flex tw-font-black tw-w-full tw-justify-between tw-mt-1.5">
                  <div>
                    <span>
                      {hasCreatorPlan() || hasPowerPlan()
                        ? "∞ "
                        : `${user?.credits ?? 0} `}
                    </span>
                    {user.credits === 1 ? "Credit" : "Credits"}
                  </div>{" "}
                  <div>|</div>
                  <div>
                    <span>{`${user?.customModels ?? 0} `}</span>
                    {user?.customModels === 1 ? "Voice Pass" : "Voices"}
                  </div>
                </div>
              </Link>
            </DropdownMenuItem>
            <div className="tw-p-2 tw-flex tw-flex-col tw-gap-1 tw-w-full">
              <DropDownLink
                icon={<Store strokeWidth="3" size={20} />}
                href={`/shop`}
                title="Item Shop"
              />
              <DropDownLink
                icon={<MicVocal strokeWidth="3" size={20} />}
                href={`/myvoices/trained`}
                title="My Voices"
              />

              <DropDownLink
                icon={<BoomBox strokeWidth="3" size={20} />}
                href={`/history`}
                title="My Covers"
              />
              <DropDownLink
                icon={<CircleDollarSign strokeWidth="3" size={20} />}
                href={`https://affiliate.jammable.com`}
                title="Affiliate Program"
              />
              <DropDownLink
                icon={<Heart strokeWidth="3" size={20} />}
                href={`https://feedback.jammable.com`}
                title="Give Feedback"
                isNew={true}
              />
              <DropDownLink
                href={`/settings`}
                title="Settings"
                icon={<Settings strokeWidth="3" size={20} />}
              />
              <div className="tw-h-0.5 tw-w-8/10 tw-bg-gray-100 tw-mx-2 tw-my-1" />

              <div
                onClick={() => {
                  auth.signOut().then(() => {
                    mixpanel.reset();
                    toast.success("Signed out!");
                    router.push(pathname);
                  });
                }}
                className="tw-text-xs hover:tw-bg-red-100 hover:tw-text-red-600 tw-rounded-full tw-transition-all tw-tracking-widest tw-p-2 tw-font-black tw-text-center"
              >
                SIGN OUT
              </div>
            </div>
          </>
        }
        className="tw-p-0 tw-w-[250px]"
      >
        {user?.avatar && (
          <Image
            src={user?.avatar}
            className="tw-rounded-full"
            width={40}
            height={40}
          />
        )}
      </Dropdown>
    </div>
  );
};

export default ProfileMenu;

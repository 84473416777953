import { MicVocal } from "lucide-react";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { FaSearch, FaHome, FaUser } from "react-icons/fa";
import { FaRadio } from "react-icons/fa6";
import { useRouter } from "next/router";
import { useAuth } from "../context/AuthProvider";
import Image from "next/image";
import { useAudioPlayer } from "./GlobalAudioPlayer";
const BottomNavbar = () => {
  const { user } = useAuth();
  const tabs = [
    {
      icon: <FaHome />,
      label: "Home",
      href: "/",
      path: "/",
      isActive: true,
    },
    {
      icon: <FaSearch />,
      label: "Voices",
      href: "/models",
      path: "/models",
      isActive: true,
    },
    {
      icon: <FaRadio />,
      label: "My Covers",
      href: "/history",
      path: "/history",
      isActive: true,
    },
    {
      icon: user?.avatar ? (
        <Image
          src={user?.avatar}
          alt="avatar"
          width={16}
          height={16}
          className="tw-rounded-full"
        />
      ) : (
        <FaUser />
      ),
      label: "Profile",
      href: user?.avatar ? `/@/${user?.username}` : "/signup",
      path: user?.avatar ? "/@/[id]" : "/signup",
      isActive: true,
    },
  ];
  const router = useRouter();
  console.log(router.pathname);
  const { isPlaying } = useAudioPlayer();

  const [isHidden, setIsHidden] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (!isPlaying) {
      setIsHidden(false);
      setTimeout(() => {
        setIsVisible(true);
      }, 50);
    } else {
      setIsVisible(false);
      setTimeout(() => {
        setIsHidden(true);
      }, 300); // Adjust this value to match your transition duration
    }
  }, [isPlaying]);

  return (
    <div
      className={`md:tw-hidden tw-w-full tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-[52] tw-justify-center tw-flex tw-pb-2 tw-px-2 ${isHidden ? "tw-hidden" : ""}`}
    >
      <div
        className={`tw-bg-white tw-z-100 tw-overflow-visible tw-flex tw-relative tw-justify-between tw-max-w-4xl tw-w-full tw-shadow-2xl tw-rounded-3xl tw-p-2 tw-py-2 tw-transition-all ${
          isVisible
            ? "tw-opacity-100 tw-h-[68px] tw-scale-100"
            : "tw-opacity-0 tw-h-0  tw-p-0"
        }`}
      >
        {tabs.map((tab) =>
          !tab.isActive ? null : (
            <Link
              key={tab?.label}
              href={tab?.href}
              className={` tw-h-full tw-rounded-2xl tw-flex tw-flex-col tw-py-2 tw-px-2 tw-items-center tw-justify-between   ${
                router.pathname === tab.path ? "tw-bg-gray-100" : " "
              }`}
            >
              <div>{tab.icon}</div>
              <span className="tw-text-sm tw-font-black">{tab.label}</span>
            </Link>
          ),
        )}
      </div>
    </div>
  );
};

export default BottomNavbar;
